import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import config from "../config";
import { toast } from "react-toastify";

// react-bootstrap components
import {
    Button,
    Card,
    Table,
    Container,
    Row,
    Col,
    Tooltip,
    OverlayTrigger,
    Modal,
    Form
} from "react-bootstrap";

export default function TransportsList() {

    const [transportsArray, setTransportsArray] = useState([])
    const [show, setShow] = useState(false);
    const [isAdd, setIsAdd] = useState(true)
    const [model_title, setModelTitle] = useState('Transport Data')

    const [id, setId] = useState('')
    const [transport_mode, setTransport_mode] = useState('')

    const [small, setSmall] = useState(0)
    const [medium, setMedium] = useState(0)
    const [large, setLarge,] = useState(0)
    const [walkAndCycle, setWalkAndCycle] = useState(0)


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios({
            method: "POST",
            url: `${config.SERVER_URL}/admin/all_transports`
        })
            .then((response) => {
                setTransportsArray(response.data);
            })
    }

    const clearData = () => {
        setId('')
        setTransport_mode('')
        setSmall(0)
        setMedium(0)
        setLarge(0)
        setWalkAndCycle(0)
    }

    // update Data
    const handleClose = () => { clearData(); setModelTitle('Add Transport'); setShow(false) };
    const handleShow = () => { clearData(); setShow(true) };
    const handleEditShow = (data) => {
        setTransport_mode(data.transport_mode)
        setSmall(data.small)
        setMedium(data.medium)
        setLarge(data.large)
        setWalkAndCycle(data.walk_and_cycle)
        setId(data._id);
        setShow(true);
        setModelTitle('Edit Transport');
    };

    const submitForm = async (e) => {
        e.preventDefault();

        if (isAdd) {
            // add data
            axios({
                method: 'POST',
                url: `${config.SERVER_URL}/admin/create_transport`,
                data: { transport_mode: transport_mode, small: small, medium: medium, large: large, walk_and_cycle: walkAndCycle }
            })
                .then((response) => {
                    toast.success(response.data.message);
                    handleClose();
                    fetchData();
                    clearData();
                }).catch((err) => {
                    toast.error(err.response.data.message);
                })
        } else {
            // edit data
            axios({
                method: 'POST',
                url: `${config.SERVER_URL}/admin/edit_transport`,
                data: { id: id, transport_mode: transport_mode, small: small, medium: medium, large: large, walk_and_cycle: walkAndCycle }
            })
                .then((response) => {
                    toast.success(response.data.message);
                    handleClose();
                    fetchData();
                    clearData();
                }).catch((err) => {
                    toast.error(err.response.data.message);
                })
        }
    }

    const deleteEvent = (id) => {
        axios({
            method: 'POST',
            url: `${config.SERVER_URL}/admin/delete_transport`,
            data: { id }
        })
            .then((response) => {
                toast.success(response.data.message);
                fetchData();
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    }

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                                <Card.Title as="h4">Transports List
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-506045838"> Transports </Tooltip>}>
                                        <Button className="btn-simple btn-link p-1 float-right " type="button" variant="success" onClick={() => { handleShow(); setIsAdd(true) }} >
                                            <i className="fas fa-user-plus fa-lg" aria-hidden="true"></i>
                                            {/* <i className="fa fa-plus-circle fa-2x" aria-hidden="true" ></i> */}
                                        </Button>
                                    </OverlayTrigger>
                                </Card.Title>
                            </Card.Header>

                            {/* popup Model for  Data */}
                            <Modal show={show} onHide={handleClose} animation={false}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{model_title}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group >
                                            <Form.Label>Transport Mode</Form.Label>
                                            <Form.Control as="select" onChange={(e) => setTransport_mode(e.target.value)} value={transport_mode}>
                                                <option>Select Type</option>
                                                <option value="car">Car</option>
                                                <option value="plug-in hybrid">Plug-in Hybrid</option>
                                                <option value="battery ev">Battery EV</option>
                                                <option value="motorbike">Motorbike</option>
                                                <option value="public transport for all journeys">Public Transport</option>
                                                <option value="walk and cycle">Walk and Cycle</option>
                                            </Form.Control>
                                        </Form.Group>
                                        {transport_mode === 'walk and cycle' ?
                                            <Form.Group>
                                                <Form.Label>Walk {'&'} Cycle ( in % )</Form.Label>
                                                <Form.Control type="number" onChange={(e) => setWalkAndCycle(e.target.value)} value={walkAndCycle}></Form.Control>
                                            </Form.Group> :
                                            <Form.Group >
                                                <Form.Label>Transport types</Form.Label>
                                                <Row>
                                                    <Col xl={3}>
                                                        <Form.Label>Small</Form.Label>
                                                        <Form.Control type="number" onChange={(e) => setSmall(e.target.value)} value={small} />
                                                    </Col>
                                                    <Col xl={3}>
                                                        <Form.Label>Medium</Form.Label>
                                                        <Form.Control type="number" onChange={(e) => setMedium(e.target.value)} value={medium} />
                                                    </Col>
                                                    <Col xl={3}>
                                                        <Form.Label>Large</Form.Label>
                                                        <Form.Control type="number" onChange={(e) => setLarge(e.target.value)} value={large} />
                                                    </Col>
                                                </Row>
                                            </Form.Group>}
                                        <Button variant="info" className="float-right" onClick={submitForm} > Submit </Button>
                                    </Form>
                                </Modal.Body>
                            </Modal>

                            <Card.Body className="table-full-width table-responsive px-0">
                                <Table className="table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th className="border-0">No.</th>
                                            <th className="border-0">Transport Mode</th>
                                            <th className="border-0">small</th>
                                            <th className="border-0">medium</th>
                                            <th className="border-0">large</th>
                                            <th className="border-0">walk And Cycle</th>
                                            <th className="border-0 float-right">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transportsArray.length !== 0 ? transportsArray.map((prop, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{(i + 1)}</td>
                                                    <td>{prop.transport_mode}</td>
                                                    <td>{prop.small}</td>
                                                    <td>{prop.medium}</td>
                                                    <td>{prop.large}</td>
                                                    <td>-{prop.walk_and_cycle}%</td>
                                                    <td className="td-actions text-right">
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip id="tooltip-488980961">
                                                                    Edit
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Button className="btn-simple btn-link p-1" type="button" variant="info" onClick={() => { handleEditShow(prop); setIsAdd(false) }} >
                                                                <i className="fas fa-edit"></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip id="tooltip-506045838">Remove</Tooltip>
                                                            }
                                                        >
                                                            <Button className="btn-simple btn-link p-1" type="button" variant="danger" onClick={() => deleteEvent(prop._id)}>
                                                                <i className="fas fa-times"></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                            )
                                        }) : <tr><td align="center" className="text-center " colSpan="8" style={{ height: '60vh' }}> No Data Found</td></tr>}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment >
    );
}