import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import config from "../config";
import { toast } from "react-toastify";
// import Select from 'react-select';

// react-bootstrap components
import {
    Button,
    Card,
    Table,
    Container,
    Row,
    Col,
    Tooltip,
    OverlayTrigger,
    Modal,
    Form
} from "react-bootstrap";


export default function HomeData() {

    const [allHomeDataArray, setAllHomeDataArray] = useState([])
    const [show, setShow] = useState(false);
    const [isAdd, setIsAdd] = useState(true)
    const [id, setId] = useState('')
    const [model_title, setModelTitle] = useState('Home Data')
    const [homeType, setHomeType] = useState('')
    const [high, setHigh] = useState(0)
    const [med, setMed] = useState(0)
    const [low, setLow] = useState(0)

    const [Bone, setBone] = useState()
    const [Btwo, setBtwo] = useState()
    const [Bthree, setBthree] = useState()
    const [Bfour, setBfour] = useState()

    const [Pone, setPone] = useState()
    const [Ptwo, setPtwo] = useState()
    const [Pthree, setPthree] = useState()
    const [Pfour, setPfour] = useState()

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios({
            method: "POST",
            url: `${config.SERVER_URL}/admin/all_homedata`
        })
            .then((response) => {
                setAllHomeDataArray(response.data);
            })
    }

    const clearData = () => {
        setId('')
        setHomeType('')
        setBone(0)
        setBtwo(0)
        setBthree(0)
        setBfour(0)
        setPone(0)
        setPtwo(0)
        setPthree(0)
        setPfour(0)
        setHigh(0)
        setMed(0)
        setLow(0)
    }


    // update Data
    const handleClose = () => { clearData(); setModelTitle('Add Data'); setShow(false) };
    const handleShow = () => { clearData(); setShow(true) };
    const handleEditShow = (data) => {
        setHomeType(data.home_type)
        setBone(data.bedrooms[0])
        setBtwo(data.bedrooms[1])
        setBthree(data.bedrooms[2])
        setBfour(data.bedrooms[3])
        setPone(data.peoples[0])
        setPtwo(data.peoples[1])
        setPthree(data.peoples[2])
        setPfour(data.peoples[3])
        setHigh(data.high)
        setMed(data.med)
        setLow(data.low)
        setId(data._id);
        setShow(true);
        setModelTitle('Edit Data');
    };

    const bedroomsValue = (i) => {
        let s;
        switch (i) {
            case 0:
                s = '1 Bedroom'
                break;
            case 1:
                s = '2 Bedrooms'
                break;
            case 2:
                s = '3 Bedrooms'
                break;
            case 3:
                s = '3 Bedrooms'
                break;
            case 4:
                s = 'more than 4 Bedrooms'
                break;
            default:
                s = ''
                break;
        }
        return s;
    }

    const peoplesValue = (i) => {
        let s;
        switch (i) {
            case 0:
                s = '1 People'
                break;
            case 1:
                s = '2 People'
                break;
            case 2:
                s = '2 People'
                break;
            case 3:
                s = '3 People'
                break;
            case 4:
                s = 'more than 4 People'
                break;
            default:
                s = ''
                break;
        }
        return s;
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if (isAdd) {
            // add data
            axios({
                method: 'POST',
                url: `${config.SERVER_URL}/admin/create_homedata`,
                data: { home_type: homeType, bedrooms: [parseFloat(Bone), parseFloat(Btwo), parseFloat(Bthree), parseFloat(Bfour)], peoples: [parseFloat(Pone), parseFloat(Ptwo), parseFloat(Pthree), parseFloat(Pfour)], high: parseFloat(high), med: parseFloat(med), low: parseFloat(low) }
            })
                .then((response) => {
                    toast.success(response.data.message);
                    handleClose();
                    fetchData();
                    clearData();
                }).catch((err) => {
                    toast.error(err.response.data.message);
                })
        } else {
            // edit data

            axios({
                method: 'POST',
                url: `${config.SERVER_URL}/admin/edit_homedata`,
                data: { home_type: homeType, bedrooms: [parseFloat(Bone), parseFloat(Btwo), parseFloat(Bthree), parseFloat(Bfour)], peoples: [parseFloat(Pone), parseFloat(Ptwo), parseFloat(Pthree), parseFloat(Pfour)], high: parseFloat(high), med: parseFloat(med), low: parseFloat(low), id: id }
            })
                .then((response) => {
                    toast.success(response.data.message);
                    handleClose();
                    fetchData();
                    clearData();
                }).catch((err) => {
                    toast.error(err.response.data.message);
                })
        }
    }

    const deleteEvent = (id) => {
        axios({
            method: 'POST',
            url: `${config.SERVER_URL}/admin/delete_homedata`,
            data: { id }
        })
            .then((response) => {
                toast.success(response.data.message);
                fetchData();
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    }

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                                <Card.Title as="h4">Home Data List
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-506045838"> Add Data </Tooltip>}>
                                        <Button className="btn-simple p-1 float-right border" type="button" variant="info" onClick={() => { handleShow(); setIsAdd(true) }} >
                                            Add New
                                            {/* <i className="fa fa-plus-circle fa-2x" aria-hidden="true" ></i> */}
                                        </Button>
                                    </OverlayTrigger>
                                </Card.Title>
                            </Card.Header>

                            {/* popup Model for  Data */}
                            <Modal show={show} onHide={handleClose} animation={false} size="lg">
                                <Modal.Header closeButton>
                                    <Modal.Title>{model_title}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group bordered="true">
                                            <Form.Label>Home type</Form.Label>
                                            <Form.Control as="select" onChange={(e) => setHomeType(e.target.value)} value={homeType}>
                                                <option>Select Type</option>
                                                <option value="detached">Detached</option>
                                                <option value="semi-detached">Semi-detached</option>
                                                <option value="terrace">Terrace</option>
                                                <option value="flat">Flat</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Bedrooms</Form.Label>
                                            <Row>
                                                <Col xl={3}>
                                                    <Form.Label>1</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setBone(e.target.value)} value={Bone} />
                                                </Col>
                                                <Col xl={3}>
                                                    <Form.Label>2</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setBtwo(e.target.value)} value={Btwo} />
                                                </Col>
                                                <Col xl={3}>
                                                    <Form.Label>3</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setBthree(e.target.value)} value={Bthree} />
                                                </Col>
                                                <Col xl={3}>
                                                    <Form.Label>more then 4</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setBfour(e.target.value)} value={Bfour} />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Label> People </Form.Label>
                                        <Form.Group >
                                            <Row>
                                                <Col xl={3}>
                                                    <Form.Label>1</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setPone(e.target.value)} value={Pone} />
                                                </Col>
                                                <Col xl={3}>
                                                    <Form.Label>2</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setPtwo(e.target.value)} value={Ptwo} />
                                                </Col>
                                                <Col xl={3}>
                                                    <Form.Label>3</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setPthree(e.target.value)} value={Pthree} />
                                                </Col>
                                                <Col xl={3}>
                                                    <Form.Label>more then 4</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setPfour(e.target.value)} value={Pfour} />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>EPC (in %) </Form.Label>
                                            <Row>
                                                <Col xl={3}>
                                                    <Form.Label>High</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setHigh(e.target.value)} value={high} />
                                                </Col>
                                                <Col xl={3}>
                                                    <Form.Label>Med</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setMed(e.target.value)} value={med} />
                                                </Col>
                                                <Col xl={3}>
                                                    <Form.Label>Low</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setLow(e.target.value)} value={low} />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Button variant="info" className="float-right" onClick={submitForm} > Submit </Button>
                                    </Form>
                                </Modal.Body>
                            </Modal>

                            <Card.Body className="table-full-width table-responsive px-0">
                                <Table className="table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th className="border-0">No.</th>
                                            <th className="border-0">Home Types</th>
                                            <th className="border-0">Bedrooms</th>
                                            <th className="border-0">Peoples</th>
                                            <th className="border-0">high</th>
                                            <th className="border-0">med</th>
                                            <th className="border-0">low</th>
                                            <th className="border-0 float-right">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allHomeDataArray.length !== 0 ? allHomeDataArray.map((prop, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{(i + 1)}</td>
                                                    <td>{prop.home_type}</td>
                                                    <td>{prop.bedrooms.map((v, i) => { return (<Fragment key={i}>({i + 1}) {v} ({bedroomsValue(i)})<br /></Fragment>) })}</td>
                                                    <td>{prop.peoples.map((v, i) => { return (<Fragment key={i}> ({i + 1}) {v} ({peoplesValue(i)})<br /></Fragment>) })}</td>
                                                    <td>-{prop.high}%</td>
                                                    <td>-{prop.med}%</td>
                                                    <td>-{prop.low}%</td>
                                                    <td className="td-actions text-right">
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip id="tooltip-488980961">
                                                                    Edit
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Button className="btn-simple btn-link p-1" type="button" variant="warning" onClick={() => { handleEditShow(prop); setIsAdd(false) }} >
                                                                <i className="fas fa-edit"></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip id="tooltip-506045838">Remove</Tooltip>
                                                            }
                                                        >
                                                            <Button className="btn-simple btn-link p-1" type="button" variant="danger" onClick={() => deleteEvent(prop._id)}>
                                                                <i className="far fa-trash-alt"></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                            )
                                        }) : <tr><td align="center" className="text-center " colSpan="8" style={{ height: '60vh' }}> No Data Found</td></tr>}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container >
        </Fragment >
    );
}