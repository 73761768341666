import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import config from '../config'

import '../assets/css/auth.css'

export default function ForgotPassword() {

    const [email, setEmail] = useState('');

    const submitForm = (e) => {
        e.preventDefault();
        setEmail(email);
        axios({
            method: 'POST',
            url: `${config.SERVER_URL}/admin/forgot_password`,
            data: { email: email.trim() }
        }).then((response) => {
            toast.success(response.data.message);
        }).catch((err) => {
            toast.error(err.response.data.message);
        })
    }

    return (
        <section className="Login authBackground">
            <div className="container">
                <div className="row py-4">
                    <div className="col-md-12">
                        <div className="row authForm justify-content-center">
                            <div className="col-md-6 col-lg-4">
                                <div className="card card-outline-secondary m-0">
                                    <div className="card-header">
                                        <h3 className="text-center">Forgot Password</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="card-body">
                                        <form className="form" autoComplete="off">
                                            <div className="form-group">
                                                <label htmlFor="uname1">Email</label>
                                                <input type="text" onChange={(e) => setEmail(e.target.value)} value={email} className="form-control" />
                                            </div>
                                            <br />
                                            <div className="text-center">
                                                <button type="submit" className="btn m-auto btn-wd login-btn" onClick={submitForm}>Submit</button>
                                            </div>
                                        </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};