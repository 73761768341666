import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import config from "../config";
// import { toast } from "react-toastify";
import BootstrapTable from 'react-bootstrap-table-next';

// react-bootstrap components
import {
  // Button,
  Card,
  // Table,
  Container,
  Row,
  Col,
  // Tooltip,
  // OverlayTrigger,
} from "react-bootstrap";

export default function UsersList() {

  const [usersArray, setUsersArray] = useState([]);
  // const [show, setShow] = useState(false);
  // const [isAdd, setIsAdd] = useState(true)
  // const [id, setId] = useState('')
  // const [model_title, setModelTitle] = useState('User')
  // const [values, setValue] = useState({
  //   email: '',
  //   name: '',
  //   password: '',
  //   confirm_password: ''
  // })

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios({
      method: "POST",
      url: `${config.SERVER_URL}/admin/all_users`
    })
      .then((response) => {
        setUsersArray(response.data.map((el, i) => {
          return {
            ...el,
            id: i + 1,
            user_offset: Number((el.user_offset).toFixed(2)),
            total_co2_offset: Number((el.total_co2_offset).toFixed(2))
          }
        }));
      })
  }

  const columns = [
    {
      dataField: 'id',
      text: 'Number'
    },
    {
      dataField: 'name',
      text: 'Full Name',
      sort: true
    },
    {
      dataField: 'email',
      text: 'email',
      sort: true
    },
    {
      dataField: "user_offset",
      text: "User Footprint",
      sort: true
    },
    {
      dataField: "total_co2_offset",
      text: "Co2 Offset",
      sort: true
    }];

  // const clearData = () => {
  //   setValue({ ...values, name: '', email: '', password: '', confirm_password: '' })
  // }

  // const deleteEvent = (id) => {
  //   axios({
  //     method: 'POST',
  //     url: `${config.SERVER_URL}/admin/delete_user`,
  //     data: { id }
  //   })
  //     .then((response) => {
  //       toast.success(response.data.message);
  //       fetchData();
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.data.message);
  //     });
  // }

  // const { name, email, password, confirm_password } = values;

  // update Data
  // const handleClose = () => { clearData(); setModelTitle('Add Data'); setShow(false) };
  // const handleShow = () => { clearData(); setShow(true) };
  // const handleEditShow = (data) => {
  //   setValue({
  //     ...values,
  //     name: data.name,
  //     email: data.email
  //   });
  //   setId(data._id);
  //   setModelTitle('Edit Data');
  //   setShow(true);
  // };

  // const handleValues = (inputValue) => (e) => {
  //   setValue({ ...values, [inputValue]: e.target.value })
  // }

  // const submitForm = (e) => {
  //   e.preventDefault();
  //   setValue({ ...values });
  //   if (isAdd) {
  //     // add data
  //     axios({
  //       method: 'POST',
  //       url: `${config.SERVER_URL}/signup`,
  //       data: { email: email.trim(), name: name.trim(), password: password.trim(), confirm_password: confirm_password.trim() }
  //     })
  //       .then((response) => {
  //         toast.success(response.data.message);
  //         handleClose();
  //         fetchData();
  //         clearData();
  //       }).catch((err) => {
  //         toast.error(err.response.data.message);
  //       })
  //   } else {

  //     // edit data
  //     axios({
  //       method: 'POST',
  //       url: `${config.SERVER_URL}/admin/edit_user`,
  //       data: { email: email.trim(), name: name.trim(), id: id }
  //     })
  //       .then((response) => {
  //         toast.success(response.data.message);
  //         handleClose();
  //         fetchData();
  //         clearData();
  //       }).catch((err) => {
  //         toast.error(err.response.data.message);
  //       })
  //   }
  // }

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Users
                  {/* <OverlayTrigger overlay={<Tooltip id="tooltip-506045838"> User</Tooltip>}>
                    <Button className="btn-simple btn-link p-1 float-right " type="button" variant="info" onClick={() => { handleShow(); setIsAdd(true) }} >
                      <i className="fas fa-user-plus fa-lg" />
                    </Button>
                  </OverlayTrigger> */}
                </Card.Title>
              </Card.Header>

              {/* popup Model for  Data */}
              {/* <Modal show={show} onHide={handleClose} animation={false} size="xl">
                <Modal.Header closeButton>
                  <Modal.Title>{model_title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group >
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control type="text" onChange={handleValues('name')} value={name} />
                    </Form.Group>
                    <Form.Group >
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" onChange={handleValues('email')} value={email} />
                    </Form.Group>
                    {isAdd === true ? <Form.Group >
                      <Form.Label>Password</Form.Label>
                      <Form.Control type="password" onChange={handleValues('password')} value={password} />
                    </Form.Group>
                      : null}
                    {isAdd === true ? <Form.Group >
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control type="password" onChange={handleValues('confirm_password')} value={confirm_password} />
                    </Form.Group> : null}
                    <Button variant="info" className="float-right" onClick={submitForm} > Submit </Button>
                  </Form>
                </Modal.Body>
              </Modal> */}

              <Card.Body className="table-full-width table-responsive px-0">

                {usersArray ?
                  <BootstrapTable keyField='id' data={usersArray} columns={columns} />
                  : <div align="center" className="text-center " colSpan="8" style={{ height: '60vh' }}> No Data Found</div>
                }

                {/* <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">No.</th>
                      <th className="border-0">Full Name</th>
                      <th className="border-0">Email</th>
                      <th className="border-0">User Footprint</th>
                      <th className="border-0">Co2 Offset</th>
                      {/* <th className="border-0 float-right">Action</th> 
                    </tr>
                  </thead>
                  <tbody>
                    {usersArray.length !== 0 ? usersArray.map((prop, i) => {
                      return (
                        <tr key={i}>
                          <td>{(i + 1)}</td>
                          <td>{prop.name}</td>
                          <td>{prop.email}</td>
                          <td>{(prop.user_offset).toFixed(2)}</td>
                          <td>{(prop.total_co2_offset).toFixed(2)}</td>
                          <td className="td-actions text-right"> 
                          <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-488980961">
                                  Edit
                              </Tooltip>
                              }
                            >
                              <Button className="btn-simple btn-link p-1" type="button" variant="warning" onClick={() => { handleEditShow(prop); setIsAdd(false) }} >
                                <i className="fas fa-edit"></i>
                              </Button>
                            </OverlayTrigger> 
                          <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-506045838">Remove</Tooltip>
                              }
                            >
                              <Button className="btn-simple btn-link p-1" type="button" variant="danger" onClick={() => deleteEvent(prop._id)}>
                                <i className="far fa-trash-alt"></i>
                              </Button>
                            </OverlayTrigger> 
                          </td> 
                        </tr>
                      )
                    }) : <tr><td align="center" className="text-center " colSpan="8" style={{ height: '60vh' }}> No Data Found</td></tr>}
                  </tbody>
                </Table> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment >
  );
}