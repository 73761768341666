import UsersList from "./views/UsersList";
import Country from "./views/Country";
import CountryTarget from "./views/CountryTarget";
import GIFsList from "./views/GIFsList";
import HomeData from "./views/HomeData";
import FoodData from "./views/FoodData";
import Payment from "./views/Payment";
import TransportsList from "./views/TransportsList";

// import TravelData from "./views/TravelData";
// import CarData from "./views/CarData";
import FlightData from "./views/FlightData";

const dashboardRoutes = [
  {
    path: "/users",
    name: "Users List",
    icon: "nc-icon nc-circle-09",
    component: UsersList,
    layout: "/admin",
  },
  {
    path: "/payment_history",
    name: "Payment History",
    icon: "fas fa-receipt",
    component: Payment,
    layout: "/admin",
  },
  {
    path: "/gifs",
    name: "GIF List",
    icon: "far fa-images",
    component: GIFsList,
    layout: "/admin",
  },
  {
    path: "/country",
    name: "Countries",
    icon: "nc-icon nc-pin-3",
    component: Country,
    layout: "/admin",
  },
  {
    path: "/countries_target",
    name: "Country Target",
    icon: "nc-icon nc-pin-3",
    component: CountryTarget,
    layout: "/admin",
  },
  {
    path: "/home_data",
    name: "Home Data",
    icon: "fas fa-home",
    component: HomeData,
    layout: "/admin",
  },
  {
    path: "/food_data",
    name: "Food Data",
    icon: "fas fa-utensils",
    component: FoodData,
    layout: "/admin",
  },
  {
    path: "/transport_data",
    name: "Transport Data",
    icon: "fas fa-road",
    component: TransportsList,
    layout: "/admin",
  },
  {
    path: "/flight_data",
    name: "Flight Data",
    icon: "fas fa-plane-departure",
    component: FlightData,
    layout: "/admin",
  },
  // {
  //   path: "/car_data",
  //   name: "Car Data",
  //   icon: "fas fa-car",
  //   component: CarData,
  //   layout: "/admin",
  // },
];

export default dashboardRoutes;
