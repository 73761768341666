import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import config from "../config";
import { toast } from "react-toastify";
// import Select from 'react-select';

// react-bootstrap components
import {
    Button,
    Card,
    Table,
    Container,
    Row,
    Col,
    Tooltip,
    OverlayTrigger,
    Modal,
    Form
} from "react-bootstrap";


export default function FlightData() {

    const [allFlightDataArray, setAllFlightDataArray] = useState([])
    const [show, setShow] = useState(false);
    const [isAdd, setIsAdd] = useState(true)
    const [id, setId] = useState('')
    const [model_title, setModelTitle] = useState('Flight Data')

    const [domestic, setDomestic] = useState(0)
    const [up_to_800_mi, setUpto800mi] = useState(0)
    const [up_to_1600_mi, setUpto1600mi] = useState(0)
    const [up_to_3500_mi, setUpto3500mi] = useState(0)
    const [up_to_5500_mi, setUpto5500mi] = useState(0)
    const [up_to_11000_mi, setUpto11000mi] = useState(0)

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios({
            method: "POST",
            url: `${config.SERVER_URL}/admin/all_flightDatas`
        })
            .then((response) => {
                setAllFlightDataArray(response.data);
            })
    }
    const clearData = () => {
        setId('')
        setDomestic(0)
        setUpto800mi(0)
        setUpto1600mi(0)
        setUpto3500mi(0)
        setUpto5500mi(0)
        setUpto11000mi(0)
    }

    // update Data
    const handleClose = () => { clearData(); setModelTitle('Add Data'); setShow(false) };
    const handleShow = () => { clearData(); setShow(true) };
    const handleEditShow = (data) => {
        setDomestic(data.domestic)
        setUpto800mi(data.up_to_800_mi)
        setUpto1600mi(data.up_to_1600_mi)
        setUpto3500mi(data.up_to_3500_mi)
        setUpto5500mi(data.up_to_5500_mi)
        setUpto11000mi(data.up_to_11000_mi)
        setId(data._id);
        setShow(true);
        setModelTitle('Edit Data');
    };

    const submitForm = async (e) => {
        e.preventDefault();

        if (isAdd) {
            // add data
            axios({
                method: 'POST',
                url: `${config.SERVER_URL}/admin/create_flightData`,
                data: { domestic: parseFloat(domestic), up_to_800_mi: parseFloat(up_to_800_mi), up_to_1600_mi: parseFloat(up_to_1600_mi), up_to_3500_mi: parseFloat(up_to_3500_mi), up_to_5500_mi: parseFloat(up_to_5500_mi), up_to_11000_mi: parseFloat(up_to_11000_mi) }
            })
                .then((response) => {
                    toast.success(response.data.message);
                    handleClose();
                    fetchData();
                    clearData();
                }).catch((err) => {
                    toast.error(err.response.data.message);
                })
        } else {
            // edit data

            axios({
                method: 'POST',
                url: `${config.SERVER_URL}/admin/edit_flightData`,
                data: { hodomestic: parseFloat(domestic), up_to_800_mi: parseFloat(up_to_800_mi), up_to_1600_mi: parseFloat(up_to_1600_mi), up_to_3500_mi: parseFloat(up_to_3500_mi), up_to_5500_mi: parseFloat(up_to_5500_mi), up_to_11000_mi: parseFloat(up_to_11000_mi), id: id }
            })
                .then((response) => {
                    toast.success(response.data.message);
                    handleClose();
                    fetchData();
                    clearData();
                }).catch((err) => {
                    toast.error(err.response.data.message);
                })
        }
    }

    const deleteEvent = (id) => {
        axios({
            method: 'POST',
            url: `${config.SERVER_URL}/admin/delete_flightData`,
            data: { id }
        })
            .then((response) => {
                toast.success(response.data.message);
                fetchData();
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    }

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                                <Card.Title as="h4">Flight Data List
                                    {allFlightDataArray.length > 0 ? null :
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-506045838"> Add </Tooltip>}>
                                            <Button className="btn-simple p-1 float-right border" type="button" variant="info" onClick={() => { handleShow(); setIsAdd(true) }} >
                                                Add New
                                                {/* <i className="fa fa-plus-circle fa-2x" aria-hidden="true" ></i> */}
                                            </Button>
                                        </OverlayTrigger>
                                    }
                                </Card.Title>
                            </Card.Header>

                            {/* popup Model for  Data */}
                            <Modal show={show} onHide={handleClose} animation={false} >
                                <Modal.Header closeButton>
                                    <Modal.Title>{model_title}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group >
                                            <Row>
                                                <Col xl={6}>
                                                    <Form.Label>Domestic (500mi)</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setDomestic(e.target.value)} value={domestic} />
                                                </Col>
                                                <Col xl={6}>
                                                    <Form.Label>Up to 800 mi</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setUpto800mi(e.target.value)} value={up_to_800_mi} />
                                                </Col>
                                                <Col xl={6}>
                                                    <Form.Label>Up to 1600 mi</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setUpto1600mi(e.target.value)} value={up_to_1600_mi} />
                                                </Col>
                                                <Col xl={6}>
                                                    <Form.Label>Up to 3500 mi</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setUpto3500mi(e.target.value)} value={up_to_3500_mi} />
                                                </Col>
                                                <Col xl={6}>
                                                    <Form.Label>Up to 5500 mi</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setUpto5500mi(e.target.value)} value={up_to_5500_mi} />
                                                </Col>
                                                <Col xl={6}>
                                                    <Form.Label>Up to 11000 mi</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setUpto11000mi(e.target.value)} value={up_to_11000_mi} />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Button variant="info" className="float-right" onClick={submitForm} > Submit </Button>
                                    </Form>
                                </Modal.Body>
                            </Modal>

                            <Card.Body className="table-full-width table-responsive px-0">
                                <Table className="table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th className="border-0">No.</th>
                                            <th className="border-0">Domestic (500mi)</th>
                                            <th className="border-0">Up to 800 mi</th>
                                            <th className="border-0">Up to 1600 mi</th>
                                            <th className="border-0">Up to 3500 mi</th>
                                            <th className="border-0">Up to 5500 mi</th>
                                            <th className="border-0">Up to 11000 mi</th>
                                            <th className="border-0 float-right">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allFlightDataArray.length !== 0 ? allFlightDataArray.map((prop, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{(i + 1)}</td>
                                                    <td>{prop.domestic}</td>
                                                    <td>{prop.up_to_800_mi}</td>
                                                    <td>{prop.up_to_1600_mi}</td>
                                                    <td>{prop.up_to_3500_mi}</td>
                                                    <td>{prop.up_to_5500_mi}</td>
                                                    <td>{prop.up_to_11000_mi}</td>
                                                    <td className="td-actions text-right">
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip id="tooltip-488980961">
                                                                    Edit
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Button className="btn-simple btn-link p-1" type="button" variant="warning" onClick={() => { handleEditShow(prop); setIsAdd(false) }} >
                                                                <i className="fas fa-edit"></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip id="tooltip-506045838">Remove</Tooltip>
                                                            }
                                                        >
                                                            <Button className="btn-simple btn-link p-1" type="button" variant="danger" onClick={() => deleteEvent(prop._id)}>
                                                                <i className="far fa-trash-alt"></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                            )
                                        }) : <tr><td align="center" className="text-center " colSpan="8" style={{ height: '60vh' }}> No Data Found</td></tr>}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container >
        </Fragment >
    );
}