import React, { Fragment } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import jwt from "jsonwebtoken"
import AdminNavbar from "components/Navbars/AdminNavbar";
// import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";
// import sidebarImage from "assets/img/sidebar-3.jpg";

function Admin({ history }) {

  if (!localStorage.getItem("admin")) {
    history.push("/")
  }

  if (localStorage.getItem("admin")) {
    let decodedToken = jwt.decode(Buffer.from(localStorage.getItem('admin'), 'base64').toString());
    if (!decodedToken || decodedToken.exp * 1000 < Date.now()) {
      localStorage.removeItem("admin");
      history.push("/")
    }
  }

  // const [image, setImage] = React.useState(sidebarImage);
  // const [color, setColor] = React.useState("azure");
  // const [hasImage, setHasImage] = React.useState(false);
  const location = useLocation();
  const mainPanel = React.useRef(null);

  const switchRoutes = (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === "/admin") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from="/admin" to="/admin/users" />
    </Switch>
  );

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);
  return (
    <Fragment>
      <div className="wrapper">
        <Sidebar color={"azure"} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Switch>
              {switchRoutes}
            </Switch>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
      {/* <FixedPlugin
        hasImage={hasImage}
        setHasImage={() => setHasImage(!hasImage)}
        color={color}
        setColor={(color) => setColor(color)}
        image={image}
        setImage={(image) => setImage(image)}
      /> */}
    </Fragment>
  );
}

export default Admin;
