import React from "react";
import ReactDOM from "react-dom";

import { HashRouter, Route, Switch } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import AdminLayout from "layouts/Admin.js";
import Signin from './auth/Signin';
import ForgotPassword from './auth/ForgotPassword';
import ResetPassword from './auth/ResetPassword';
import Signout from './auth/Signout';

ReactDOM.render(
  <HashRouter>
    <ToastContainer />
    <Switch>
      <Route path='/' exact component={Signin} />
      <Route path="/forgot_password" exact component={ForgotPassword} />
      <Route path="/reset-password/:token" exact component={ResetPassword} />
      <Route path="/signout" exact component={Signout} />
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      {/* <Redirect from="/admin" to="/admin/users" /> */}
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
