import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import config from '../config'
import { Link } from 'react-router-dom';

import '../assets/css/auth.css';

export default function Signin({ history }) {

    const [values, setValue] = useState({
        email: '',
        password: ''
    })

    if (localStorage.getItem("admin")) {
        history.push("/admin")
    }

    const { email, password } = values;

    const handleChange = (inputValue) => (e) => {
        setValue({ ...values, [inputValue]: e.target.value })
    }

    const submitForm = (e) => {
        e.preventDefault();
        setValue({ ...values });

        axios({
            method: 'POST',
            url: `${config.SERVER_URL}/admin/signin`,
            data: { email: email.trim(), password: password.trim() }
        })
            .then((response) => {
                toast.success(response.data.message);
                setValue({ ...values, email: '', password: '' })
                localStorage.setItem("admin", response.data.token);
                if (localStorage.getItem("admin")) {
                    history.push("/admin")
                }
            }).catch((err) => {
                toast.error(err.response.data.message);
            })
    }

    return (
        <section className="Login authBackground">
            <div className="container">
                <div className="row py-4">
                    <div className="col-md-12">
                        <div className="row authForm justify-content-center">
                            <div className="col-md-6 col-lg-4">
                                <div className="card card-outline-secondary m-0">
                                    <div className="card-header">
                                        <h3 className="text-center">Login</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="card-body">
                                            <form className="form" autoComplete="off">
                                                <div className="form-group">
                                                    <label htmlFor="abcd">Email</label>
                                                    <input type="text" onChange={handleChange('email')} value={email} placeholder="Enter email" className="form-control" />
                                                    <div className="invalid-feedback">Please enter your username or email</div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Password</label>
                                                    <input type="password" onChange={handleChange('password')} value={password} placeholder="Enter password" className="form-control" />
                                                    <div className="invalid-feedback">Please enter a password</div>
                                                </div>
                                                <div className="small">
                                                    <Link to={'/forgot_password'}>Forgot Password</Link>
                                                </div>
                                                <br />
                                                <div className="text-center">
                                                    <button type="submit" className="btn m-auto btn-wd login-btn" onClick={submitForm}>Login</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
