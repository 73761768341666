import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import config from "../config";
import { toast } from "react-toastify";
// import Select from 'react-select';

// react-bootstrap components
import {
    Button,
    Card,
    Table,
    Container,
    Row,
    Col,
    Tooltip,
    OverlayTrigger,
    Modal,
    Form
} from "react-bootstrap";


export default function FoodData() {

    const [allFoodDataArray, setAllFoodDataArray] = useState([])
    const [show, setShow] = useState(false);
    const [isAdd, setIsAdd] = useState(true)
    const [id, setId] = useState('')
    const [model_title, setModelTitle] = useState('Food Data')

    const [dietone, setDietone] = useState(0)
    const [diettwo, setDiettwo] = useState(0)
    const [dietthree, setDietthree] = useState(0)
    const [dietfour, setDietfour] = useState(0)
    const [dietfive, setDietfive] = useState(0)
    const [dietsix, setDietsix] = useState(0)

    const [producedone, setProducedone] = useState(0)
    const [producedtwo, setProducedtwo] = useState(0)
    const [producedthree, setProducedthree] = useState(0)
    const [producedfour, setProducedfour] = useState(0)

    const [costone, setCostone] = useState(0)
    const [costtwo, setCosttwo] = useState(0)
    const [costthree, setCostthree] = useState(0)
    const [costfour, setCostfour] = useState(0)
    const [costfive, setCostfive] = useState(0)

    const [wastedone, setWastedone] = useState(0)
    const [wastedtwo, setWastedtwo] = useState(0)
    const [wastedthree, setWastedthree] = useState(0)
    const [wastedfour, setWastedfour] = useState(0)
    const [wastedfive, setWastedfive] = useState(0)

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios({
            method: "POST",
            url: `${config.SERVER_URL}/admin/all_foodData`
        })
            .then((response) => {
                setAllFoodDataArray(response.data);
            })
    }
    const clearData = () => {
        setId('')
        setDietone(0)
        setDiettwo(0)
        setDietthree(0)
        setDietfour(0)
        setDietfive(0)
        setDietsix(0)
        setProducedone(0)
        setProducedtwo(0)
        setProducedthree(0)
        setProducedfour(0)
        setCostone(0)
        setCosttwo(0)
        setCostthree(0)
        setCostfour(0)
        setCostfive(0)
        setWastedone(0)
        setWastedtwo(0)
        setWastedthree(0)
        setWastedfour(0)
        setWastedfive(0)
    }

    // update Data
    const handleClose = () => { clearData(); setModelTitle('Add Data'); setShow(false) };
    const handleShow = () => { clearData(); setShow(true) };
    const handleEditShow = (data) => {
        setDietone(data.diet[0])
        setDiettwo(data.diet[1])
        setDietthree(data.diet[2])
        setDietfour(data.diet[3])
        setDietfive(data.diet[4])
        setDietsix(data.diet[5])
        setProducedone(data.produced[0])
        setProducedtwo(data.produced[1])
        setProducedthree(data.produced[2])
        setProducedfour(data.produced[3])
        setCostone(data.spend[0])
        setCosttwo(data.spend[1])
        setCostthree(data.spend[2])
        setCostfour(data.spend[3])
        setCostfive(data.spend[4])
        setWastedone(data.wasted[0])
        setWastedtwo(data.wasted[1])
        setWastedthree(data.wasted[2])
        setWastedfour(data.wasted[3])
        setWastedfive(data.wasted[4])
        setId(data._id);
        setShow(true);
        setModelTitle('Edit Data');
    };

    const dietValue = (i) => {
        let s;
        switch (i) {
            case 0:
                s = 'Meat in every meal'
                break;
            case 1:
                s = 'Meat in some meals'
                break;
            case 2:
                s = 'Meat very rarely'
                break;
            case 3:
                s = 'No beef'
                break;
            case 4:
                s = 'Vegetarian'
                break;
            case 5:
                s = 'Vegan'
                break;
            default:
                s = ''
                break;
        }
        return s;
    }

    const localProducedValue = (i) => {
        let s;
        switch (i) {
            case 0:
                s = 'Most'
                break;
            case 1:
                s = 'Some'
                break;
            case 2:
                s = 'I Don\'t worry'
                break;
            case 3:
                s = 'Never cook'
                break;
            default:
                s = ''
                break;
        }
        return s;
    }

    const eatoutcostValue = (i) => {
        let s;
        switch (i) {
            case 0:
                s = '£0-1'
                break;
            case 1:
                s = '£1-10'
                break;
            case 2:
                s = '£11-25'
                break;
            case 3:
                s = '£26-55'
                break;
            case 4:
                s = '> £55'
                break;
            default:
                s = ''
                break;
        }
        return s;
    }
    const foodwasteValue = (i) => {
        let s;
        switch (i) {
            case 0:
                s = 'None'
                break;
            case 1:
                s = '0-5%'
                break;
            case 2:
                s = '6-15%'
                break;
            case 3:
                s = '16-30%'
                break;
            case 4:
                s = '> 30%'
                break;
            default:
                s = ''
                break;
        }
        return s;
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if (isAdd) {
            // add data
            axios({
                method: 'POST',
                url: `${config.SERVER_URL}/admin/create_foodData`,
                data: {
                    diet: [parseFloat(dietone), parseFloat(diettwo), parseFloat(dietthree), parseFloat(dietfour), parseFloat(dietfive), parseFloat(dietsix)],
                    produced: [parseFloat(producedone), parseFloat(producedtwo), parseFloat(producedthree), parseFloat(producedfour)],
                    spend: [parseFloat(costone), parseFloat(costtwo), parseFloat(costthree), parseFloat(costfour), parseFloat(costfive)],
                    wasted: [parseFloat(wastedone), parseFloat(wastedtwo), parseFloat(wastedthree), parseFloat(wastedfour), parseFloat(wastedfive)],
                }
            })
                .then((response) => {
                    toast.success(response.data.message);
                    handleClose();
                    fetchData();
                    clearData();
                }).catch((err) => {
                    toast.error(err.response.data.message);
                })
        } else {
            // edit data

            axios({
                method: 'POST',
                url: `${config.SERVER_URL}/admin/edit_foodData`,
                data: {
                    id: id,
                    diet: [parseFloat(dietone), parseFloat(diettwo), parseFloat(dietthree), parseFloat(dietfour), parseFloat(dietfive), parseFloat(dietsix)],
                    produced: [parseFloat(producedone), parseFloat(producedtwo), parseFloat(producedthree), parseFloat(producedfour)],
                    spend: [parseFloat(costone), parseFloat(costtwo), parseFloat(costthree), parseFloat(costfour), parseFloat(costfive)],
                    wasted: [parseFloat(wastedone), parseFloat(wastedtwo), parseFloat(wastedthree), parseFloat(wastedfour), parseFloat(wastedfive)]
                }
            })
                .then((response) => {
                    toast.success(response.data.message);
                    handleClose();
                    fetchData();
                    clearData();
                }).catch((err) => {
                    toast.error(err.response.data.message);
                })
        }
    }

    const deleteEvent = (id) => {
        axios({
            method: 'POST',
            url: `${config.SERVER_URL}/admin/delete_foodData`,
            data: { id }
        })
            .then((response) => {
                toast.success(response.data.message);
                fetchData();
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    }

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                                <Card.Title as="h4">Food Data List
                                    {allFoodDataArray.length > 0 ? null : <OverlayTrigger overlay={<Tooltip id="tooltip-506045838"> Add </Tooltip>}>
                                        <Button className="btn-simple p-1 float-right border" type="button" variant="info" onClick={() => { handleShow(); setIsAdd(true) }} >
                                            Add New
                                            {/* <i className="fa fa-plus-circle fa-2x" aria-hidden="true" ></i> */}
                                        </Button>
                                    </OverlayTrigger>}
                                </Card.Title>
                            </Card.Header>

                            {/* popup Model for  Data */}
                            <Modal show={show} onHide={handleClose} animation={false} size="xl">
                                <Modal.Header closeButton>
                                    <Modal.Title>{model_title}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group >
                                            <Form.Label>Diet</Form.Label>
                                            <Row>
                                                <Col xl={2}>
                                                    <Form.Label>Meat in every meal</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setDietone(e.target.value)} value={dietone} />
                                                </Col>
                                                <Col xl={2}>
                                                    <Form.Label>Meat in some meals</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setDiettwo(e.target.value)} value={diettwo} />
                                                </Col>
                                                <Col xl={2}>
                                                    <Form.Label>Meat very rarely</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setDietthree(e.target.value)} value={dietthree} />
                                                </Col>
                                                <Col xl={2}>
                                                    <Form.Label>No beef</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setDietfour(e.target.value)} value={dietfour} />
                                                </Col>
                                                <Col xl={2}>
                                                    <Form.Label>Vegetarian</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setDietfive(e.target.value)} value={dietfive} />
                                                </Col>
                                                <Col xl={2}>
                                                    <Form.Label>Vegan</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setDietsix(e.target.value)} value={dietsix} />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Local Produced</Form.Label>
                                            <Row>
                                                <Col xl={2}>
                                                    <Form.Label>Most</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setProducedone(e.target.value)} value={producedone} />
                                                </Col>
                                                <Col xl={2}>
                                                    <Form.Label>Some</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setProducedtwo(e.target.value)} value={producedtwo} />
                                                </Col>
                                                <Col xl={2}>
                                                    <Form.Label>I don't worry</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setProducedthree(e.target.value)} value={producedthree} />
                                                </Col>
                                                <Col xl={2}>
                                                    <Form.Label>Never cook</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setProducedfour(e.target.value)} value={producedfour} />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Label> Eat out cost (in %)</Form.Label>
                                        <Form.Group >
                                            <Row>
                                                <Col xl={2}>
                                                    <Form.Label>£0-1</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setCostone(e.target.value)} value={costone} />
                                                </Col>
                                                <Col xl={2}>
                                                    <Form.Label>£1-10</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setCosttwo(e.target.value)} value={costtwo} />
                                                </Col>
                                                <Col xl={2}>
                                                    <Form.Label>£11-25</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setCostthree(e.target.value)} value={costthree} />
                                                </Col>
                                                <Col xl={2}>
                                                    <Form.Label>£26-55</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setCostfour(e.target.value)} value={costfour} />
                                                </Col>
                                                <Col xl={2}>
                                                    <Form.Label> {">"} £55</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setCostfive(e.target.value)} value={costfive} />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Food Waste (in %) </Form.Label>
                                            <Row>
                                                <Col xl={2}>
                                                    <Form.Label>None</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setWastedone(e.target.value)} value={wastedone} />
                                                </Col>
                                                <Col xl={2}>
                                                    <Form.Label>0-5%</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setWastedtwo(e.target.value)} value={wastedtwo} />
                                                </Col>
                                                <Col xl={2}>
                                                    <Form.Label>6-15%</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setWastedthree(e.target.value)} value={wastedthree} />
                                                </Col>
                                                <Col xl={2}>
                                                    <Form.Label>16-30%</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setWastedfour(e.target.value)} value={wastedfour} />
                                                </Col>
                                                <Col xl={2}>
                                                    <Form.Label>{">"}30%</Form.Label>
                                                    <Form.Control type="number" onChange={(e) => setWastedfive(e.target.value)} value={wastedfive} />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Button variant="info" className="float-right" onClick={submitForm} > Submit </Button>
                                    </Form>
                                </Modal.Body>
                            </Modal>

                            <Card.Body className="table-full-width table-responsive px-0">
                                <Table className="table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th className="border-0">No.</th>
                                            <th className="border-0">Diet </th>
                                            <th className="border-0">Local Produced</th>
                                            <th className="border-0">Eat out cost</th>
                                            <th className="border-0">Food Waste</th>
                                            <th className="border-0 float-right">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allFoodDataArray.length !== 0 ? allFoodDataArray.map((prop, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{(i + 1)}</td>
                                                    <td>{prop.diet.map((v, i) => { return (<Fragment key={i}>({i + 1}) {v} ({dietValue(i)}) <br /></Fragment>) })}</td>
                                                    <td>{prop.produced.map((v, i) => { return (<Fragment key={i}>({i + 1}) {v}% ({localProducedValue(i)})<br /></Fragment>) })}</td>
                                                    <td>{prop.spend.map((v, i) => { return (<Fragment key={i}> ({i + 1}) {v}% ({eatoutcostValue(i)})<br /></Fragment>) })}</td>
                                                    <td>{prop.wasted.map((v, i) => { return (<Fragment key={i}> ({i + 1}) {v}% ({foodwasteValue(i)})<br /></Fragment>) })}</td>
                                                    <td className="td-actions text-right">
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip id="tooltip-488980961">
                                                                    Edit
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Button className="btn-simple btn-link p-1" type="button" variant="warning" onClick={() => { handleEditShow(prop); setIsAdd(false) }} >
                                                                <i className="fas fa-edit"></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip id="tooltip-506045838">Remove</Tooltip>
                                                            }
                                                        >
                                                            <Button className="btn-simple btn-link p-1" type="button" variant="danger" onClick={() => deleteEvent(prop._id)}>
                                                                <i className="far fa-trash-alt"></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                            )
                                        }) : <tr><td align="center" className="text-center " colSpan="8" style={{ height: '60vh' }}> No Data Found</td></tr>}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container >
        </Fragment >
    );
}