import React, { Fragment } from 'react';
import { Redirect } from "react-router-dom";

const Signout = () => {
    return (
        <Fragment>
            {localStorage.removeItem("admin")}
            <Redirect to="/" />
        </Fragment>
    )

}

export default Signout;