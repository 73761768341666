import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import config from "../config";
// import { toast } from "react-toastify";

// react-bootstrap components
import {
    Card,
    Table,
    Container,
    Row,
    Col,
} from "react-bootstrap";

export default function Payment() {

    const [usersArray, setPaymentsArray] = useState([])

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios({
            method: "POST",
            url: `${config.SERVER_URL}/admin/all_payments`
        })
            .then((response) => {
                setPaymentsArray(response.data);
            })
    }

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                                <Card.Title as="h4">Payment History</Card.Title>
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <Table className="table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th className="border-0">No.</th>
                                            <th className="border-0">user name</th>
                                            <th className="border-0">payment type</th>
                                            <th className="border-0">transaction id</th>
                                            <th className="border-0">offset amount</th>
                                            <th className="border-0">offset Co2</th>
                                            <th className="border-0">Payment time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {usersArray.length !== 0 ? usersArray.map((prop, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{(i + 1)}</td>
                                                    <td>{!prop.user_id.name ? '-' : prop.user_id.name}</td>
                                                    <td>{!prop.is_paypal ? 'Paypal' : 'Card'}</td>
                                                    <td>{!prop.transaction_id ? '-' : prop.transaction_id}</td>
                                                    <td>£{!prop.offset_amount ? '-' : prop.offset_amount}</td>
                                                    <td>{!prop.offset ? '-' : prop.offset}</td>
                                                    <td>{!prop.created_at ? '-' : prop.created_at}</td>
                                                </tr>
                                            )
                                        }) : <tr><td align="center" className="text-center " colSpan="8" style={{ height: '60vh' }}> No Data Found</td></tr>}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment >
    );
}