import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import config from '../config'

const ResetPassword = ({ match }) => {

    const [password, setPassword] = useState('');
    const token = match.params.token;

    const clickSubmit = event => {
        event.preventDefault();
        setPassword(password);
        axios({
            method: 'POST',
            url: `${config.SERVER_URL}/admin/reset_password`,
            data: { password: password.trim(), token: token.trim() }
        })
            .then(async (response) => {
                toast.success(response.data.message);
            })
            .catch(error => {
                toast.error(error.response.data.message);
            });
    };

    return (
        <section className="Login authBackground">
            <div className="container">
                <div className="row py-4">
                    <div className="col-md-12">
                        <div className="row authForm justify-content-center">
                            <div className="col-md-6 col-lg-4">
                                <div className="card card-outline-secondary m-0">
                                    <div className="card-header">
                                        <h3 className="text-center">Reset Password</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="card-body">
                                        <form className="form" autoComplete="off">
                                            <div className="form-group">
                                                <label htmlFor="uname1">New Password</label>
                                                <input type="text" onChange={(e) => setPassword(e.target.value)} value={password} className="form-control" />
                                            </div>
                                            <br />
                                            <div className="text-center">
                                                <button type="submit" className="btn m-auto btn-wd login-btn" onClick={clickSubmit}>Submit</button>
                                            </div>
                                        </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ResetPassword;