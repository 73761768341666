import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import config from "../config";
import { toast } from "react-toastify";

// react-bootstrap components
import {
    Button,
    Card,
    Table,
    Container,
    Row,
    Col,
    Tooltip,
    OverlayTrigger,
    Modal,
    Form
} from "react-bootstrap";

export default function GIFsList() {

    const [gifsArray, setGIFsArray] = useState([])
    const [show, setShow] = useState(false);
    // const [isAdd, setIsAdd] = useState(true)
    // const [id, setId] = useState('')
    const [model_title, setModelTitle] = useState('GIF')

    const [isGIFSelected, setGIFSelected] = useState(null);
    const [showGIF, setShowGIF] = useState(null);
    // const [values, setValue] = useState({ title: '' })

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios({
            method: "POST",
            url: `${config.SERVER_URL}/admin/all_gifs`
        })
            .then((response) => {
                setGIFsArray(response.data);
            })
    }

    const clearData = () => {
        // setValue({ ...values, title: '' });
        setGIFSelected(null);
        setShowGIF(null);
    }

    // const deleteEvent = (id) => {
    //     axios({
    //         method: 'POST',
    //         url: `${config.SERVER_URL}/admin/delete_gif`,
    //         data: { id }
    //     })
    //         .then((response) => {
    //             toast.success(response.data.message);
    //             fetchData();
    //         })
    //         .catch((err) => {
    //             toast.error(err.response.data.message);
    //         });
    // }

    // const { title } = values;

    // update Data
    const handleClose = () => { clearData(); setModelTitle('Add Data'); setShow(false) };
    const handleShow = () => { clearData(); setShow(true) };

    const handleEditShow = (data) => {
        // setValue({
        //     ...values,
        //     title: data.title
        // });
        // setId(data._id);
        setModelTitle('Edit Data');
        setShow(true);
        setShowGIF(data.gif_link);
    };

    const GIFUpload = (e) => {
        var file, img;
        if ((file = e.target.files[0])) {
            img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = function () {
                if (img.width >= 720 && img.height >= 1280) {
                    setGIFSelected(e.target.files[0]);
                    setShowGIF(URL.createObjectURL(e.target.files[0]));
                } else {
                    toast.error("The resolution of GIF must be min 720*1280.");
                }
            };
            img.onerror = function () {
                toast.error("not a valid file: " + file.type);
            };
        }
    }

    // const handleValues = (inputValue) => (e) => {
    //     setValue({ ...values, [inputValue]: e.target.value })
    // }

    const submitForm = (e) => {
        e.preventDefault();

        const formData = new FormData();
        // formData.append("title", title)
        formData.append("gif_link", isGIFSelected);

        if (!isGIFSelected || isGIFSelected.name.slice(-4) !== '.gif') {
            toast.error("Please Select a gif file.")
            return;
        }

        // if (isAdd) {
        // add data
        axios({
            method: 'POST',
            url: `${config.SERVER_URL}/admin/single_gif_update`,
            data: formData
        })
            .then((response) => {
                toast.success(response.data.message);
                handleClose();
                fetchData();
                clearData();
            }).catch((err) => {
                toast.error(err.response.data.message);
            })
        // } else {
        //     // edit data
        //     axios({
        //         method: 'POST',
        //         url: `${config.SERVER_URL}/admin/single_gif_update`,
        //         data: formData
        //     })
        //         .then((response) => {
        //             toast.success(response.data.message);
        //             handleClose();
        //             fetchData();
        //             clearData();
        //         }).catch((err) => {
        //             toast.error(err.response.data.message);
        //         })
        // }
    }

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                                <Card.Title as="h4">GIF Table
                                    {gifsArray.length > 0 ? null :
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-506045838">Add GIF</Tooltip>}>
                                            <Button className="btn-simple btn-link p-1 float-right border" type="button" variant="info" onClick={() => { handleShow() }} >
                                                Add GIF
                                            </Button>
                                        </OverlayTrigger>}
                                </Card.Title>
                            </Card.Header>

                            {/* popup Model for  Data */}
                            <Modal show={show} onHide={handleClose} animation={false}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{model_title}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        {/* <Form.Group >
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control type="text" onChange={handleValues('title')} value={title} />
                                        </Form.Group> */}
                                        <Form.Group >
                                            <Form.Label>GIF File</Form.Label>
                                            <Form.Control type="file" onChange={GIFUpload} accept="image/gif" />
                                        </Form.Group>
                                        <Form.Group className="text-center">
                                            {showGIF ? <img src={showGIF} alt="" height="250px" width="250px" /> : null}
                                        </Form.Group>
                                        <Button variant="info" className="float-right" onClick={submitForm} > Submit </Button>
                                    </Form>
                                </Modal.Body>
                            </Modal>

                            <Card.Body className="table-full-width table-responsive px-0">
                                <Table className="table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th className="border-0">No.</th>
                                            {/* <th className="border-0">Title</th> */}
                                            <th className="border-0">GIF</th>
                                            <th className="border-0 float-right">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {gifsArray.length !== 0 ? gifsArray.map((prop, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{(i + 1)}</td>
                                                    {/* <td>{prop.title}</td> */}
                                                    <td><img src={prop.gif_link} alt="" height="200px" width="200px" /></td>
                                                    <td className="td-actions text-right">
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip id="tooltip-488980961">
                                                                    Edit
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Button className="btn-simple btn-link p-1" type="button" variant="warning" onClick={() => { handleEditShow(prop) }} >
                                                                <i className="fas fa-edit"></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                        {/* <OverlayTrigger
                                                            overlay={
                                                                <Tooltip id="tooltip-506045838">Remove</Tooltip>
                                                            }
                                                        >
                                                            <Button className="btn-simple btn-link p-1" type="button" variant="danger" onClick={() => deleteEvent(prop._id)}>
                                                                <i className="far fa-trash-alt"></i>
                                                            </Button>
                                                        </OverlayTrigger> */}
                                                    </td>
                                                </tr>
                                            )
                                        }) : <tr><td align="center" className="text-center " colSpan="8" style={{ height: '60vh' }}> No Data Found</td></tr>}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment >
    );
}